import React from "react";
import Laptop from "../../assets/mocks/Rectangle.svg";
const Project1 = () => {
  return (
    <div className="bg-yellow-300 h-full flex justify-center items-center">
      <img src={Laptop} alt="" srcset="" />
    </div>
  );
};

export default Project1;
