import React from "react";
import Mac from "../../assets/mocks/MacBook Air (15 inch).png";
const Project3 = () => {
  return (
    <div className="bg-blue-500 h-full flex justify-center items-center">
      <img src={Mac} alt="" srcset="" />
    </div>
  );
};

export default Project3;
