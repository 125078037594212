import React from "react";
import phones from "../../assets/mocks/Rectangle.png";
const Project2 = () => {
  return (
    <div className="bg-[#6DABB1] h-full flex justify-center items-center">
      <img src={phones} alt="" srcset="" />
    </div>
  );
};

export default Project2;
